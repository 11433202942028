/* eslint-disable camelcase */
export default function (component, utmName) {
  if (component.$route.fullPath.includes('utm_')) {
    const {
      utm_source, utm_medium, utm_campaign, utm_term, utm_content 
    } = component.$route.query;

    localStorage.setItem(utmName, JSON.stringify({
      source: utm_source,
      medium: utm_medium,
      campaign: utm_campaign,
      term: utm_term,
      content: utm_content,
      fullLink: window.location.href
    }));
  }
}
