<route>
{
  "name": "onboarding",
  "meta": {
    "title": "Onboarding",
    "auth": false
  }
}
</route>

<template>
  <div class="splash-page" @click="roll">
    <div ref="container" class="flex-grow vcenter">
      <swiper ref="swiper" :options="swiperOptions" @click-slide="onSwiperClickSlide" @set-translate="onSetTranslate">
        <swiper-slide>
          <div class="center">
            <img class="img-size" src="@/assets/images/splash1.png" />
          </div>
          <div class="title1 mb-8">{{ $t('splash.german_hospitals_are') }}</div>
          <div class="text3 mb">{{ $t('splash.our_scholarship_is_provided') }}</div>
        </swiper-slide>
        <swiper-slide>
          <div class="center">
            <img class="img-size" src="@/assets/images/splash2.png" />
          </div>
          <div class="title1 mb-8">{{ $t('splash.work_and_learn_at_the_same') }}</div>
          <div class="text3 mb">{{ $t('splash.study_the_german_language') }}</div>
        </swiper-slide>
        <swiper-slide>
          <div class="center">
            <img class="img-size" src="@/assets/images/splash3.png" />
          </div>
          <div class="title1 mb-8">{{ $t('splash.scholarship_will_be_covered') }}</div>
          <div class="text3 mb">{{ $t('splash.after_successful_deployment') }}</div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="swiper-button-next" ref="next"></div>
    </div>

    <ui-button @click="next">
      <span>{{ $t('splash.next') }}</span>
    </ui-button>
  </div>
</template>

<script>
// import core version + navigation, pagination modules（>= Swiper 6.x）
import SwiperClass, { Navigation, Pagination /* add some modules if you need */ } from 'swiper';

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';

// ----------------------------------------------------------------------------------------
// import style of all modules (>= Swiper 6.x)
// all Swiper styles including all components styles (like Navigation, Pagination, etc.)
// ----------------------------------------------------------------------------------------
import 'swiper/swiper-bundle.css';

// import style of only use module (>= Swiper 6.x)
// only core Swiper styles
import 'swiper/swiper.min.css';
// styles required for Navigation component
import 'swiper/components/navigation/navigation.min.css';
// styles required for Pagination component
import 'swiper/components/pagination/pagination.min.css';
import UiButton from '../components/ui/UiButton';
import saveUtmData from '../utils/saveUtmData';
// configure Swiper to use modules
SwiperClass.use([Navigation, Pagination]);

export default {
  layout: 'auth',
  name: 'onboarding',
  components: {
    Swiper,
    SwiperSlide,
    UiButton
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      isShowingFinishButton: false,
      swiperOptions: {
        // loop: true,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  },
  created() {
    saveUtmData(this, 'registerUtm');
  },
  mounted() {
    this.$refs.container.style.maxHeight = `${Math.max(420, window.innerHeight - 160)}px`;
  },
  methods: {
    onSetTranslate() {
      console.log('onSetTranslate');
    },
    onSwiperSlideChangeTransitionStart() {
      console.log('onSwiperSlideChangeTransitionStart');
    },
    onSwiperClickSlide(index, reallyIndex) {
      console.log('Swiper click slide!', reallyIndex);
    },
    roll() {
      /* const swiper = this.$refs.swiper.$swiper;
        if (swiper.isEnd) {
          swiper.slideTo(0);
        } else {
          swiper.slideNext();
        } */
    },
    next() {
      const swiper = this.$refs.swiper.$swiper;
      if (swiper.isEnd) {
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'finish_onboarding_slides');
        this.$router.push({ name: 'register' });
      } else {
        swiper.slideNext();
      }
    }
  }
};
</script>

<style lang="scss">
.vcenter {
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex-basis: 10px;
  max-height: 450px; // calc(100vh - 160px);
}
.splash-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .swiper-container {
    width: 100%;
  }

  .swiper-slide {
    padding-bottom: 20px;
  }

  .swiper-button-next {
    display: none !important;
  }

  .swiper-pagination-bullet {
    background: #abd4fe;
    opacity: 1;
    margin: 2px 6px !important;
  }

  .swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;
    background: #258ffb;
    margin: 0px 6px !important;
  }

  .img-size {
    width: 256px;
    height: 256px;
    margin: auto;
  }
}
</style>
